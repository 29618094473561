function HomePage() {
    return (
      <div className="container mx-auto p-8 text-center">
        <h1 className="text-5xl font-extrabold text-gray-800 mb-6">
          Welcome to <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-600">AI Task Scheduler</span>
        </h1>
        <p className="text-xl text-gray-600 mb-8">
          Organize your tasks efficiently with AI-powered scheduling.
        </p>
        <img
          src="/assets/illustration.png"
          alt="Task scheduling illustration"
          className="mx-auto w-full max-w-md"
        />
      </div>
    );
  }
  
  export default HomePage;