// src/store/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loading: true,
  },
  reducers: {
    setUser(state, action) {
      const { uid, email, displayName, photoURL } = action.payload || {};
      state.user = { uid, email, displayName, photoURL };
      state.loading = false;
    },
    clearUser(state) {
      state.user = null;
      state.loading = false;
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;

export const listenToAuthChanges = () => (dispatch) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // Extract only serializable fields
      const { uid, email, displayName, photoURL } = user;
      dispatch(setUser({ uid, email, displayName, photoURL }));
    } else {
      dispatch(clearUser());
    }
  });
};

export default authSlice.reducer;