import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteTaskAsync } from '../store/tasksSlice';

function TaskItem({ task }) {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteTaskAsync(task.id));
  };

  return (
    <div className="p-4 bg-white rounded shadow-md flex justify-between items-center">
      <div>
        <h3 className="text-lg font-semibold">{task.title}</h3>
        <p className="text-sm text-gray-600">{task.description}</p>
        <div className="text-sm text-gray-500">
          <p>Priority: {task.priority}</p>
          <p>Estimated Duration: {task.estimatedDuration} hours</p>
          <p>Deadline: {new Date(task.deadline).toLocaleDateString()}</p>
          {task.scheduledStartTime && task.scheduledEndTime && (
            <p>
              Scheduled: {new Date(task.scheduledStartTime).toLocaleString()} -{' '}
              {new Date(task.scheduledEndTime).toLocaleString()}
            </p>
          )}
        </div>
      </div>
      <button
        onClick={handleDelete}
        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
      >
        Delete
      </button>
    </div>
  );
}

export default TaskItem;