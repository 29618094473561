import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Navbar() {
  const user = useSelector((state) => state.auth.user);

  return (
    <nav className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between">
        <div className="text-xl font-bold">AI Task Scheduler</div>
        <div className="space-x-4">
          <Link to="/">Home</Link>
          {user && <Link to="/tasks">Tasks</Link>}
          {user && <Link to="/calendar">Calendar</Link>}
          {user && <Link to="/settings">Settings</Link>}
          {!user && <Link to="/login">Login</Link>}
          {!user && <Link to="/signup">Sign Up</Link>}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
