import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaskAsync } from '../store/tasksSlice';

// Initialize moment localizer
const localizer = momentLocalizer(moment);

// Enhance Calendar with Drag and Drop capabilities
const DnDCalendar = withDragAndDrop(Calendar);

function CalendarView({ events }) {
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks.items);

  const handleEventDrop = (data) => {
    const { event, start, end } = data;

    // Find the corresponding task
    const task = tasks.find((t) => t.id === event.id);
    if (task) {
      // Dispatch action to update the task's scheduled times
      dispatch(
        updateTaskAsync({
          ...task,
          scheduledStartTime: start.toISOString(),
          scheduledEndTime: end.toISOString(),
        })
      );
    }
  };

  const handleEventResize = (data) => {
    const { event, start, end } = data;

    // Find the corresponding task
    const task = tasks.find((t) => t.id === event.id);
    if (task) {
      // Dispatch action to update the task's scheduled times
      dispatch(
        updateTaskAsync({
          ...task,
          scheduledStartTime: start.toISOString(),
          scheduledEndTime: end.toISOString(),
        })
      );
    }
  };

  const eventStyleGetter = (event) => {
    // Define colors based on priority
    let backgroundColor = '#3174ad'; // Default color

    const task = tasks.find((t) => t.id === event.id);
    if (task) {
      switch (task.priority) {
        case 'High':
          backgroundColor = '#e53e3e'; // Red
          break;
        case 'Medium':
          backgroundColor = '#dd6b20'; // Orange
          break;
        case 'Low':
          backgroundColor = '#38a169'; // Green
          break;
        default:
          backgroundColor = '#3174ad';
      }
    }

    const style = {
      backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  };

  return (
    <div className="bg-white rounded shadow-md p-4">
      <DnDCalendar
        localizer={localizer}
        events={events}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        resizable
        style={{ height: '600px', width: '100%' }}
        defaultView="week"
        selectable
        draggableAccessor={() => true}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
}

export default CalendarView;