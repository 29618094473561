import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CalendarView from '../components/CalendarView';
import { fetchTasks } from '../store/tasksSlice';

function CalendarPage() {
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks.items || []);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && tasks.length === 0) {
      dispatch(fetchTasks(user.uid));
    }
  }, [dispatch, user, tasks.length]);

  if (!Array.isArray(tasks)) {
    return <p>Loading tasks...</p>;
  }

  const events = tasks
    .filter((task) => task.scheduledStartTime && task.scheduledEndTime)
    .map((task) => ({
      id: task.id,
      title: task.title,
      start: new Date(task.scheduledStartTime),
      end: new Date(task.scheduledEndTime),
      allDay: false,
    }));

  return <CalendarView events={events} />;
}

export default CalendarPage;