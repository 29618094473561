import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../firebase';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';

// Async Thunks
export const fetchTasks = createAsyncThunk(
  'tasks/fetchTasks',
  async (userId) => {
    const q = query(collection(db, 'tasks'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const tasks = [];
    querySnapshot.forEach((doc) => {
      tasks.push({ id: doc.id, ...doc.data() });
    });
    return tasks;
  }
);

export const addTaskAsync = createAsyncThunk(
  'tasks/addTaskAsync',
  async (task) => {
    console.log('Adding task:', task); // Log the task data
    const docRef = await addDoc(collection(db, 'tasks'), task);
    return { id: docRef.id, ...task };
  }
);

export const updateTaskAsync = createAsyncThunk(
  'tasks/updateTaskAsync',
  async (task) => {
    const taskRef = doc(db, 'tasks', task.id);
    await updateDoc(taskRef, task);
    return task;
  }
);

export const deleteTaskAsync = createAsyncThunk(
  'tasks/deleteTaskAsync',
  async (taskId) => {
    const taskRef = doc(db, 'tasks', taskId);
    await deleteDoc(taskRef);
    return taskId;
  }
);

// Slice
const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    items: [], // Ensure this is set to an empty array
    status: null,
    error: null,
  },
  reducers: {
    // Synchronous reducer to reorder tasks
    reorderTasks(state, action) {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Tasks
      .addCase(fetchTasks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Add Task
      .addCase(addTaskAsync.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      // Update Task
      .addCase(updateTaskAsync.fulfilled, (state, action) => {
        const index = state.items.findIndex((task) => task.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      // Delete Task
      .addCase(deleteTaskAsync.fulfilled, (state, action) => {
        state.items = state.items.filter((task) => task.id !== action.payload);
      });
  },
});

// Export Actions
export const { reorderTasks } = tasksSlice.actions;

// Export Reducer
export default tasksSlice.reducer;