import axios from 'axios';
import { updateTaskAsync } from '../store/tasksSlice';
import store from '../store';

export async function optimizeSchedule(tasks, settings) {
  try {
    const response = await axios.post('/api/optimizeSchedule', {
      tasks,
      settings,
    });

    if (response.data && Array.isArray(response.data.optimizedSchedule)) {
      // Update tasks in Firestore with scheduled times
      response.data.optimizedSchedule.forEach((task) => {
        store.dispatch(updateTaskAsync(task));
      });
      return response.data.optimizedSchedule;
    } else {
      console.error('Invalid response format:', response.data);
      return tasks.map((task) => ({
        ...task,
        scheduledStartTime: null,
        scheduledEndTime: null,
      }));
    }
  } catch (error) {
    console.error('Error optimizing schedule:', error);
    return tasks.map((task) => ({
      ...task,
      scheduledStartTime: null,
      scheduledEndTime: null,
    }));
  }
}