// src/pages/TasksPage.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTaskAsync, fetchTasks, updateTaskAsync } from '../store/tasksSlice';
import { optimizeSchedule } from '../utils/aiScheduler';
import TaskForm from '../components/TaskForm';
import TaskList from '../components/TaskList';

function TasksPage() {
  const dispatch = useDispatch();
  const { items: tasks, status, error } = useSelector((state) => state.tasks);
  const { user } = useSelector((state) => state.auth);
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    if (user) {
      dispatch(fetchTasks(user.uid));
    }
  }, [dispatch, user]);

  const handleAddTask = (taskData) => {
    const newTask = {
      ...taskData,
      userId: user.uid,
      scheduledStartTime: null,
      scheduledEndTime: null,
      // Add any other required fields here
    };
    console.log('New task data:', newTask); // Log the new task data
    dispatch(addTaskAsync(newTask));
  };

  const handleOptimize = async () => {
    const optimizedTasks = await optimizeSchedule(tasks, settings);
    // Update each task in Firestore
    optimizedTasks.forEach((task) => {
      dispatch(updateTaskAsync(task));
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Tasks</h2>
      <TaskForm onSubmit={handleAddTask} />
      <button
        onClick={handleOptimize}
        className="my-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
      >
        Optimize Schedule
      </button>
      {status === 'loading' && <p>Loading tasks...</p>}
      {error && <p className="text-red-500">Error: {error}</p>}
      <TaskList tasks={tasks} />
    </div>
  );
}

export default TasksPage;