// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration (replace with your own config)
const firebaseConfig = {
    apiKey: "AIzaSyBZ8KUP_YfSf-VTGh7GbaBaeZmx1FCis0U",
    authDomain: "sedai-501a4.firebaseapp.com",
    projectId: "sedai-501a4",
    storageBucket: "sedai-501a4.appspot.com",
    messagingSenderId: "735637774552",
    appId: "1:735637774552:web:8a878439ced8656bc037ad",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Auth
const auth = getAuth(app);

export { db, auth };