import React, { useState } from 'react';

function TaskForm({ onSubmit, initialData = {} }) {
  const [title, setTitle] = useState(initialData.title || '');
  const [description, setDescription] = useState(initialData.description || '');
  const [estimatedDuration, setEstimatedDuration] = useState(initialData.estimatedDuration || '');
  const [deadline, setDeadline] = useState(initialData.deadline || '');
  const [priority, setPriority] = useState(initialData.priority || 'Medium');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title || !estimatedDuration || !deadline) {
      alert('Please fill in all required fields.');
      return;
    }
    onSubmit({ title, description, estimatedDuration, deadline, priority });
    // Clear form fields
    setTitle('');
    setDescription('');
    setEstimatedDuration('');
    setDeadline('');
    setPriority('Medium');
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 bg-gray-100 p-4 rounded shadow-md">
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className="w-full p-2 border rounded"
        required
      />
      <textarea
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className="w-full p-2 border rounded"
      ></textarea>
      <input
        type="number"
        placeholder="Estimated Duration (hours)"
        value={estimatedDuration}
        onChange={(e) => setEstimatedDuration(e.target.value)}
        className="w-full p-2 border rounded"
        required
        min="1"
      />
      <input
        type="date"
        placeholder="Deadline"
        value={deadline}
        onChange={(e) => setDeadline(e.target.value)}
        className="w-full p-2 border rounded"
        required
      />
      <select
        value={priority}
        onChange={(e) => setPriority(e.target.value)}
        className="w-full p-2 border rounded"
      >
        <option value="High">High</option>
        <option value="Medium">Medium</option>
        <option value="Low">Low</option>
      </select>
      <button type="submit" className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
        Add Task
      </button>
    </form>
  );
}

export default TaskForm;
